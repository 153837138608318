import React from "react";
import { graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import styled from "styled-components";

const ProjectsPage = ({ data }) => {
	return (
		<Layout pageTitle="Projects">
			<PageHeading>
				<h1>My projects</h1>
				<p>
					This page contains all my projects. Including web projects & 3D
					modeling projects.
				</p>
				<hr />
			</PageHeading>
			<ProjectsGrid>
				{data.allMarkdownRemark.edges.map((project) => (
					<div className="project-card" key={project.node.id}>
						<Link to={project.node.fields.slug}>
							<div className="img-wrapper">
								<GatsbyImage
									image={getImage(project.node.frontmatter.image)}
									alt={project.node.frontmatter.title}
								/>
							</div>
							<div className="text-wrapper">
								<h2>{project.node.frontmatter.title}</h2>
								<p>{project.node.frontmatter.desc}</p>
							</div>
						</Link>
					</div>
				))}
			</ProjectsGrid>
		</Layout>
	);
};

const PageHeading = styled.div`
	width: 50%;
	margin: auto;
	hr {
		margin-top: 1rem;
		border: none;
		border-top: 3px solid var(--accent);
	}
	@media (max-width: 1300px) {
		width: 70%;
	}
	@media (max-width: 500px) {
		width: 90%;
	}
`;

const ProjectsGrid = styled.div`
	width: 50%;
	margin: auto;
	padding-block: 2rem;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
	gap: 1rem;
	.project-card {
		border-radius: 0.5rem;
		overflow: hidden;
		a {
			text-decoration: none;
			color: var(--fg);
		}
		.img-wrapper {
			margin-bottom: 0.3rem;
			border-bottom-left-radius: 0.5rem;
			border-bottom-right-radius: 0.5rem;
			overflow: hidden;
			transition: border-radius 150ms;
			img {
				transition: all 150ms;
			}
		}
		.text-wrapper {
			padding: 1rem;
			margin-bottom: 0.3rem;
		}
		&:hover {
			background: var(--hoverBg);
			.img-wrapper {
				border-radius: 0;
				img {
					transform: scale(1.1);
				}
			}
		}
	}
	@media (max-width: 1300px) {
		width: 70%;
	}
	@media (max-width: 500px) {
		width: 90%;
	}
`;

export const query = graphql`
	query ProjectsQuery {
		allMarkdownRemark(
			sort: { fields: frontmatter___title, order: ASC }
			filter: {
				fields: { type: { eq: "project" } }
				frontmatter: { published: { eq: true } }
			}
		) {
			edges {
				node {
					frontmatter {
						title
						desc
						image {
							childImageSharp {
								gatsbyImageData(placeholder: DOMINANT_COLOR)
							}
						}
					}
					id
					fields {
						slug
					}
				}
			}
		}
	}
`;

export default ProjectsPage;
